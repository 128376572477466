
.radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 4px 0;
}

.radio {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.radio-title {
    color: #4d4e50;
    font-weight: 600;
    margin: 0;
}
