
.sign-in-bp-select-card {
    border: solid 1px #EBEAE9;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    gap: 6px;
    font-size: 13px;
    font-weight: bold;
    padding: 0 10px;
    color: #5A5A5D;
    cursor: pointer;
}

.sign-in-bp-select-code {
    color: var(--secondary);
}

.sign-in-bp-select-name {

}