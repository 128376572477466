
.purchase-order-content {
    text-align: left;
    max-width: 800px;
}

.purchase-order-page-heading {
    font-size: 28px;
    font-weight: bold;
    color: #c4c4c4;
    margin: 40px 0 30px 0;
}

.purchase-order-no {
    font-size: 28px;
    color: #5a5a5d;
    font-weight: bold;
    margin: 30px 0 18px 0;
}

.purchase-order-sub-heading {
    font-size: 22px;
    color: #5a5a5d;
    margin: 10px 0 4px 0;
}

.purchase-order-sub-value {
    font-size: 22px;
    color: #5a5a5d;
    font-weight: bold;
    margin: 0 0 0 0;
}

.purchase-order-line-heading {
    font-size: 28px;
    font-weight: bold;
    color: #c4c4c4;
    margin: 40px 0 20px 0;
}

.purchase-order-content .button {
    margin: 30px 0 0 0;
}

.purchase-order-confirmation {
    font-size: 28px;
    color: #5a5a5d;
    font-weight: bold;
    margin: 30px 0 30px 0;
}