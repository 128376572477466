
.sign-in-header {
    font-size: 24px;
    color: #5a5a5d;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.sign-in-content-border {
    border: 1px solid #EBEAE9;
    border-radius: 10px;
    padding: 25px 20px;
    width: 290px;
    flex-grow: 1;
}

.sign-in-main-content {
    text-align: left;
}

.sign-in-text {
    font-size: 13px;
    font-weight: normal;
    color: #5a5a5d;
}

.sign-in-main-content li {
    list-style-position: inside;
}

.sign-in-sub-heading {
    margin: 0 0 15px 0;
    font-weight: bold;
    color: #5a5a5d;
    font-size: 19px;
}

.sign-in-sub-sub-heading {
    margin: 20px 0 10px 0;
    font-weight: bold;
    color: #5a5a5d;
    font-size: 13px;
}

.sign-in-input-container {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.sign-in-input-container .input {
    background: transparent;
    border: solid 1px #EBE9E9;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: normal;
    color: #5a5a5d;
    width: 100%;
}

.sign-in-input-container .input:focus {
    outline: none !important;
    border: 1px solid var(--secondary);

    margin-bottom: 11px;
    max-width: 100%;
}

.sign-in-input-container .button {
    width: 100px;
    font-size: 17px;
    margin-bottom: 40px;
}

.forgot-pass-link {
    color: var(--secondary);
    text-decoration: underline;
    font-weight: bold;
    font-size: 13px;
    margin-top: 40px;
    cursor: pointer;
}

.sign-in-error {
    color: var(--warning);
    font-weight: bold;
    margin: 10px 0 14px 0;
    font-size: 15px;
}

.sign-in-remember-me {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.sign-in-remember-me p {
    margin: 0 0 0 6px;
    font-size: 13px;
    text-align: center;
}


@media (max-width: 768px) {

    .sign-in-content-border {
        width: revert;
    }

    .sign-in-input-container {
        margin-bottom: 0;
    }


    .sign-in-input-container .input {
        font-size: 16px;
    }

}
