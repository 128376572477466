
.purchase-order-line-container {
    display: grid;
    grid-template-columns: 34px 2fr 1fr 1fr 1fr;
    column-gap: 12px;
}

.purchase-order-line-container {
    padding: 8px;
}

.purchase-order-line-container img {
    max-width: 80px;
    max-height: 80px;
    margin-right: 10px;
}

.purchase-order-line-header {
    border-bottom: #5a5a5d solid 2px;
}

.purchase-order-line-header p {
    color: #5a5a5d;
    margin: 0;
    font-weight: bold;
}

.purchase-order-line {
    border-bottom: #c4c4c4 solid 1px;
}

.purchase-order-line p {
    color: #5a5a5d;
    font-size: 17px;
}

.purchase-order-line .input {
    width: 80px;
    padding-right: 12px;
    font-size: 17px;
}