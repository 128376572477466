
.selector-container {
    display: flex;
    border: 1px solid #EBEAE9;
    border-radius: 6px;
    position: relative;
    height: 100%;
    background-color: white;
}

.selector-container:has(select:focus) {
    border: 1px solid var(--secondary);
}

.selector-container select {
    background-color: transparent;
    border: none;
    height: 100%;
    width: 100%;
    padding: 6px 13px;

    -webkit-appearance: none; /* For Chrome */
    -moz-appearance: none;    /* For Firefox */
    appearance: none;

    font-family: inherit;
    font-weight: bold;
    font-size: 13px;
    color: #5A5A5D;
}

.selector-arrow-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    right: 0;
    pointer-events: none;
}

.selector-container.single-entry {
    cursor: auto;
}

.selector-container.single-entry img {
    display: none;
}

.selector-container img {
    height: 9px;
    margin: auto 13px;
    transition: transform 0.3s;
}

.selector-container select:focus ~.selector-arrow-container img {
    transform: rotate(180deg);
}
