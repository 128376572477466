
.checkbox-container {

    height: 20px;
    width: 20px;
    outline: solid 1px #EBE9E9;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    background-clip: padding-box;
    margin: 1px;

}

.checkbox-container:hover {
    background: #f0f0f0;
}

.checkbox-container.checked {
    background:  var(--secondary) !important;
}

.checkbox-container svg {
    margin: 2px 2px;
    width: 16px;
    height: 16px;
}