
.prod-tile {
    border: solid 1px #EBEAE9;
    border-radius: 10px;
    background: #ffffff;
    text-align: left;
    width: 240px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.prod-tile-main-content {
    margin: 10px 20px 15px 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.prod-tag {
    background: var(--secondary);
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-weight: bold;
    color: #ffffff;
    border-radius: 10px 10px 0 0;
}

.prod-img-container {
    max-width: 96px;
    max-height: 96px;
    width: 96px;
    height: 96px;
    margin: 8px auto;
}

.prod-img-container img {
    max-width: 96px;
    max-height: 96px;
}

.prod-purchase-info {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.prod-code {
    font-weight: bold;
    font-size: 15px;
    color: #c4c4c4;
    margin: 0;
}

.prod-name {
    font-weight: bold;
    font-size: 19px;
    color: #5a5a5d;
    margin: 10px 0 0 0;
}

.prod-size {
    font-size: 16px;
    color: #5a5a5d;
    margin: 8px 0 auto 0;
}

.prod-price-container {
    margin: 20px 0 0 0;
}

.prod-price {
    font-weight: bold;
    font-size: 23px;
    color: #5a5a5d;
    margin: 0 0 0 0;
}

.prod-price-text {
    color: #c4c4c4;
    font-weight: 300;
    font-size: 13px;
    margin: 0;
}

.prod-price-unit-price {
    color: #c4c4c4;
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 8px 0;
}

.prod-price-mark-down {
    color: #c4c4c4;
    font-weight: 400;
    text-decoration: line-through;
    font-size: 18px;
}

.prod-price-promo {
    margin: 0 0 12px 0;
    font-weight: bold;
    color: #5a5a5d;
    font-size: 14px;
}

.prod-vat {
    font-weight: normal;
    font-size: 13px;
    color: #c4c4c4;
}

.prod-input {
    margin-top: 20px;
}

.prod-input-divider {
    background: #EBEAE9;
    height: 1px;
}

.prod-selector-container {
    margin: 10px 0 0 0;
    height: 40px;
}

.prod-more-details {
    font-weight: bold;
    font-size: 15px;
    color: var(--secondary);
    text-decoration: underline;
    cursor: pointer;
}

.prod-input-quantity-container {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.prod-input-quantity-container .input {
    background: #fff;
    border: solid 1px #EBEAE9;
    border-right: none;
    border-radius: 4px 0 0 4px;
    font-size: 17px;
    margin: 0;
    flex: 1;
    min-width: 0;
}

.prod-input-quantity-container .button {
    border-radius: 0 4px 4px 0;
    font-size: 17px;
}

.prod-input-quantity-container .button.loading {
    border: solid 1px #EBEAE9;
    box-sizing: border-box;
    border-left: none;
}

.prod-tile.skeleton p {
    color: transparent;
}

.prod-promo {
    background: var(--primary);
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    padding: 4px 6px;
}

.prod-delete {
    background: var(--warning);
    height: 20px;
    width: 20px;
}


@media (max-width:768px) {

    .prod-tile {
        width: auto;
    }

}
