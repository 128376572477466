

.cart-container {
    background-color: var(--highlight);
    width: 800px;
    margin: 20px auto;
    padding: 30px 18px;
    text-align: left;
}

.cart-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    border-bottom: 2px solid #4d4e50;
    padding-bottom: 4px;
}

.cart-h1 {
    margin: 0;
    font-size: 26px;
    font-weight: 700;
    color: #4d4e50;
}

.cart-delivery-note {
    display: flex;
    flex-direction: column;
    text-align: right;
    gap: 2px;
    padding-bottom: 4px;
}

.cart-note {
    margin: 0;
    font-size: 12px;
    color: #838485;
}

.cart-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
}

.cart-h2 {
    font-size: 20px;
    font-weight: 700;
    color: #4d4e50;
    padding-bottom: 6px;
    border-bottom: 2px solid #838485;
}

.cart-l {
    grid-column: 1;
}

.cart-r {
    grid-column: 2;
}

.cart-h-r {
    text-align: right;
}

.cart-line-container {
    grid-column: span 2;
    margin-top: 4px;
}

.cart-line-grid {
    display: grid;
    grid-template-columns: 4fr 1fr 2fr 1fr;
    padding: 6px 2px;
    align-items: center;
}

.cart-line-grid :nth-child(2), .cart-line-grid :nth-child(3) {
    margin-right: 12px;
}

.cart-line-grid:nth-child(2n) {
    background-color: #F4F4F4;
}

.cart-line-h1 {
    font-size: 20px;
    font-weight: 700;
    color: #4d4e50;
    margin: 0;
}

.cart-line-grid .input {
    border-right: 0;
    height: 26px;
    padding: 0 4px;
    margin: 0;
    width: 60px;
    flex: none;
    font-size: 16px;
    text-align: right;
}

.cart-line-grid .prod-selector-container {
    width: 120px;
}

.cart-line-grid .selector-arrow-container {
    margin-right: 0;
    width: 30px;
}

.cart-line-grid select {
    height: 26px;
    padding: 0 30px 0 8px;
    font-size: 13px;
}

.cart-line-grid .button {
    margin: 0;
    height: 26px;
    font-size: 12px;
    display: flex;
    padding: 0 8px;
    width: 60px;
}

.cart-line-h2 {
    font-size: 16px;
    color: #4d4e50;
    margin: 0;
    font-weight: 500;
}

/*.cart-line-delete-button {*/
/*    height: 26px;*/
/*    width: 26px;*/
/*    background-color: #f61414;*/
/*    border-radius: 50%;*/
/*    cursor: pointer;*/
/*}*/

/*.cart-line-delete-button svg {*/
/*    margin: 4px;*/
/*    height: 18px;*/
/*    width: 18px;*/
/*}*/

.cart-line-grid .prod-input {
    margin: 0;
    display: flex;
    flex-direction: column;
}

.cart-line-grid .prod-input-divider {
    display: none;
}

.cart-line-grid .prod-selector-container {
    height: auto;
    margin: 0;
}

.cart-line-grid .prod-input-quantity-container {
    margin: 0;
    height: auto;
}

.cart-line-grid .prod-selector-container:has(> *) {
    margin-bottom: 4px;
}

.cart-line-input {
    display: flex;
    gap: 6px;
}

.cart-line-delete {
    height: 24px;
    width: 24px;
    margin: auto 0;
    cursor: pointer;
}

.cart-line-delete:active {
    filter: brightness(0.9);
    transition: filter 0s;
}

.cart-delivery-msg {
    margin: 0 0;
}

.cart-delivery-msg span {
    font-weight: 700;
}

.cart-section {
    display: flex;
    flex-direction: column;
}

.cart-full-width {
    grid-column: span 2;
    margin-top: 4px;
}

.cart-info-split {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: end;
}

.cart-info-split p {
    margin: 0;
}

.cart-summary-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.cart-summary-line p {
    font-size: 18px;
    margin: 0;
}

.cart-summary-line.b p {
    font-weight: 700;
}

.cart-input, .cart-container .card-detail-input .input {
    background: transparent;
    border: solid 1px #EBE9E9;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: normal;
    color: #5a5a5d;
    width: 100%;
}

.cart-input:focus, .cart-container .card-detail-input .input:focus {
    outline: none !important;
    border: 1px solid var(--secondary);

    margin-bottom: 11px;
    max-width: 100%;
}

.cart-container .card-detail-input .input-error {
    border: solid 1px #d89393;
}

.cart-container .clickable-text {
    color: var(--secondary);
    font-size: 13px;
    font-weight: 700;
    text-decoration: underline;
}

.cart-h {
    color: #4d4e50;
    font-weight: 600;
    margin: 12px 0 6px 0;
}

.cart-t {
    color: #4d4e50;
    margin: 0;
}

.cart-address-line {
    margin: 2px 0;
    font-size: 16px;
}

.cart-sub-section {
    margin: 12px 0;
}

.cart-warning {
    font-weight: 600;
    font-size: 14px;
    color: var(--warning);
}

.cart-container .card-detail-input {
    margin-top: 16px;
}

.cart-blocker {
    color: #4d4e50;
    font-weight: 600;
    font-size: 13px;
}

.cart-confirmation-header {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 10px;
}

.cart-confirm-line-grid {
    display: grid;
    column-gap: 24px;
    grid-template-columns: 60px 1fr 40px repeat(3, 100px);
    margin: 6px 0;
}

.cart-confirm-line-grid .cart-h {
    margin: 0;
}

.cart-line-h2.r {
    text-align: right;
}

.cart-confirm-line-grid:nth-child(2n-1) {
    background-color: #F4F4F4;
    padding: 4px 0;
}


@media (max-width: 900px) {

    .cart-container {
        width: auto;
        padding: 30px 12px;
    }

    .cart-content {
        display: flex;
        flex-direction: column;
    }

    .cart-r {
        grid-column: 1;
    }

    .cart-header {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .cart-delivery-note {
        text-align: left;
        order: -1;
        margin-bottom: 8px;
    }

    .cart-sub-section .calendar {
        margin: 0 auto;
    }

    .cart-confirmation-header {
        grid-template-columns: 1fr 0 1fr 1fr 0
    }

    .cart-confirmation-header div {
        overflow-x: hidden;
    }

    .cart-confirmation-header .cart-h {
        font-size: 14px;
    }

    .cart-confirmation-header .cart-line-h2 {
        font-size: 14px;
    }

    .cart-confirm-line-grid {
        grid-template-columns: 60px 1fr 40px 75px 0 80px;
        column-gap: 0;
    }

    .cart-confirm-line-grid p {
        font-size: 14px;
        overflow-x: hidden;
        margin: 0 4px;
    }

    .cart-confirm-line-grid .cart-line-h2.r {
        margin-right: 10px;
    }

    .cart-line-container {
        font-size: 14px;
    }

    .cart-line-container .cart-line-h1 {
        font-size: 14px;
    }

    .cart-line-container .cart-line-h2 {
        font-size: 13px;
    }

    .cart-line-grid {
        grid-template-columns: 4fr 1fr 5fr 1fr;
    }

    .cart-line-container p:nth-child(1) {
        margin-right: 10px;
    }

}
