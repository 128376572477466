
.breadcrumb-container {
    display: flex;
    margin: 40px 0;
    flex-wrap: wrap;
    row-gap: 6px;
}

.breadcrumb {
    font-weight: bold;
    font-size: 16px;
    color: #838485;
    padding: 2px 0;
    margin-right: 10px;
}

.breadcrumb:hover {
    color: #5a5a5d;
    cursor: pointer;
}

.breadcrumb.selected {
    background: #5a5a5d;
    color: #fff;
    border-radius: 4px;
    cursor: revert;
    padding: 2px 10px;
}

.breadcrumb-arrow {
    height: 16px;
    margin: auto 10px auto 0;
}

.breadcrumb-arrow:nth-last-child(1) {
    display: none;
}

@media (max-width:768px) {

    .breadcrumb-container {
        margin: 24px 0;
    }

}
