
.home-content-container {
    width: min(1180px, calc(100vw - 60pt));
    max-width: 1180px;
    margin: 0 auto;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: #4d4e50;
    column-gap: 16px;
    row-gap: 16px;
}

.home-content-header {
    font-weight: bold;
    grid-column: span 2;
    font-size: 42px;
    margin: 20px 0;
}

.home-content-tile {
    display: grid;
    grid-template-areas: "image heading"
        "image text"
        "image link";
    grid-template-columns: 78px 1fr;
    margin-bottom: 20px;
    grid-column-gap: 20px;
}

.home-content-tile > svg {
    grid-area: image;
}

.home-content-tile-icon {
    grid-area: image;
}

.home-content-tile-heading {
    group-reset: heading;
    font-weight: bold;
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 4px;
}

.home-content-tile-text {
    grid-area: text;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 18px;
    font-weight: 300;
}

.home-content-tile-nav {
    font-size: 14px;
    color: var(--secondary);
    margin: 0;
    cursor: pointer;
}
