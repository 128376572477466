
.delivery-count-down-container {
    position: sticky;
    height: 0;
    bottom: 0;
    z-index: 10;
    overflow: visible;
}

.delivery-count-down {

    max-width: 260px;
    right: 60px;
    bottom: 100px;

    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4pt;
    text-align: left;

    background: var(--highlight);
    border: #e5e5e5 solid 1px;
    border-radius: 10px;

    padding: 14px 12px;

}

.delivery-count-down-header {
    margin: 0;
    width: 100%;
    font-size: 15px;
    color: #4c4c4c;
}


.delivery-count-down-note {
    font-size: 13px;
    margin: 0;
    text-align: left;
    color: #c4c4c4;
}


@media (max-width: 768px) {

    .delivery-count-down {
        box-sizing: border-box;
        max-width: 100vw;
        width: 100vw;

        right: 0;
        bottom: 0;

        border-radius: 0;

        border-left: none;
        border-right: none;
        border-bottom: none;
    }

    .delivery-count-down-container {
        height: 200px;
    }


}
