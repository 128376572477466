
.header {
    width: 100%;
    max-width: 100%;
    background: #FDFDFD;
    position: relative;
    z-index: 15;
}

.header-new {
    max-width: 1280px;
    width: min(1280px, calc(100vw - 60pt));
    margin: 0 auto;
    display: grid;
    column-gap: 65pt;
    grid-template-areas: "logo search action"
            "nav nav nav"
            "snav snav snav";
    grid-template-columns: auto 1fr auto;
    position: relative;
    padding: 20pt 0;
}

.header-logo-container {
    grid-area: logo;
    margin-right: auto;
    margin-top: auto;
    cursor: pointer;
}

.header-logo-container img {
    max-height: 50pt;
}

.header-search-container {
    grid-area: search;
    margin: auto 0;
}

.header-action-container {
    grid-area: action;
    display: flex;
    gap: 10pt;
    margin: auto 0;

}

.header-nav-container {
    grid-area: nav;
    margin-top: 30pt;
}

.secondary-nav {
    grid-area: snav;
}

.header-burger-container {
    display: none;
}

.header-icon {
    height: 30pt;
    width: 30pt;
    align-self: center;

    stroke: var(--primary);
    fill: var(--primary);
}

.header-action {
    height: 35pt;
    align-self: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    margin-bottom: auto;
}

.header-action p {
    margin: 0;
    font-size: 0.9em;
    color: var(--primary);
}

.header-button {
    display: grid;
    grid-template-areas: "heading icon notify"
        "sub-heading sub-heading empty";
    grid-template-rows: 24px 20px;
    grid-row-gap: 2pt;
    grid-column-gap: 2pt;
    cursor: pointer;
    height: 40px;
    font-size: 0.85em;
}

.header-icon-container {
    grid-area: icon;
    margin-left: 2pt;
}

.header-icon-container svg {
    height: 34px;
    width: auto;
}

.header-button-heading {
    grid-area: heading;
    font-weight: bold;
    font-size: 12pt;
    padding-top: 4pt;
    z-index: 10;
}

.header-button-sub-heading {
    grid-area: sub-heading;
    font-size: 10pt;
    z-index: 10;
}

.header-button p {
    margin: 0;
    text-align: left;
    color: #4D4E50;
    font-family: Inter;
}

.header-notification-count {
    width: 10px;
    overflow: visible;
}

.header-notification {
    height: 14pt;
    width: 14pt;
    border-radius: 50%;
    background-color: var(--secondary);
    color: var(--highlight);
    font-weight: 700;
    font-size: 10pt;
    display: flex;
    position: relative;
    z-index: 1;
    left: -8pt;
    top: -4pt;
}

.header-notification p {
    width: 100%;
    color: var(--highlight);
    text-align: center;
    margin: auto 0;
}

.header-banner {
    height: 4px;
    width: 100%;
    background-color: #f0f0f0;
}

.header-banner-content {
    max-width: 1280px;
    display: flex;
    margin: auto;
    justify-content: space-between;
}

.header-banner-info,.header-banner-actions {
    height: 34px;
    display: flex;
    align-items: center;
}

.header-banner p {
    font-weight: 600;
    color: var(--highlight);
    margin: 0;
    font-size: 0.9em;
}

.header-banner svg {
    height: 14pt;
    max-width: 14pt;
    margin: 0 2pt 0 12pt;
}

.header-banner svg path {
    fill: var(--highlight);
    stroke: var(--highlight);
}

.header-banner-actions svg {
    margin: 0;
}

.header-flag-div {
    width: 100%;
}

.header-flag-div div {
    height: 2pt;
}

@media (max-width: 768px) {

    .header {
        position: sticky;
        top: -101px;
    }


    /*.header-banner {*/
    /*    position: sticky;*/
    /*    top: 0;*/
    /*}*/

    .header-new {
        display: grid;
        width: 100%;
        grid-template-columns: 36pt 1fr 36pt;
        grid-template-rows: auto 36pt;
        grid-template-areas:
                "logo logo logo"
                "burger search action";
        row-gap: 10pt;
        column-gap: 0;
    }

    .header-burger-container {
        display: block;
        grid-area: burger;
        margin: auto;
    }

    .header-logo-container {
        margin: auto;
    }

    .header-nav-container {
        display: none;
    }

    .header-action-container {
        margin: auto;
    }

    .header-button.account {
        display: none;
    }

    .cart-icon {
        max-height: 24pt;
    }

    .header-button-heading {
        display: none;
    }

    .header-button-sub-heading {
        display: none;
    }

    .header-notification-count {
        width: 0;
    }

    .header-banner {
        padding: 0 10pt;
        box-sizing: border-box;
    }

}

@media (max-width: 370px) {

    /*.header-new {*/
    /*    grid-template-areas:*/
    /*        "logo logo"*/
    /*        "burger action"*/
    /*        "search search";*/

    /*    grid-template-columns: auto auto;*/
    /*}*/

    .search-bar-input-container input {
        max-width: calc(100vw - (64pt + 40px + 12pt + 10pt));
    }

}

@media (min-width: 769px) and (max-width: 960px) {

    .header-new {
        grid-template-areas: "logo action"
            "search search"
            "nav nav"
            "snav snav";

        grid-template-columns: auto 1fr;
    }

    .header-action-container {
        justify-content: right;
    }



}

