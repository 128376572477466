
.category-page-top {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
}

.category-browse-container {
    display: grid;
    grid-template-columns: 100pt 1fr;
    overflow: hidden;
}

.category-browse-side-panel {
    overflow-y: scroll;
    overflow-x: hidden;
    border-right: var(--primary) 1px solid;
    background-color: var(--highlight);
}

.category-browse-main {
}

.category-parent-button {
    max-width: 100%;
    box-sizing: border-box;
    padding: 4pt 0;
    border-bottom: var(--primary) 1px solid;
}

.category-parent-button img {
    max-width: 60pt
}

.category-parent-button p {
    margin: 0;
    font-size: 1em;
    font-weight: 600;
}

.category-browse-main {
    box-sizing: border-box;
    padding: 2pt 0 0 8pt;
    font-weight: 500;
    color: var(--secondary);
    text-decoration: underline;
    overflow-y: scroll;
}

.category-browse-main p {
    text-align: left;
}

.category-all {
    font-size: 1.2em;
}

@media (min-width: 769px) {

    .category-page-top {
        display: block;
        max-width: 1280px;
        margin: auto;
        height: auto;
    }

    .category-browse-container {
        grid-template-columns: 1fr auto;
        padding: 20pt 16pt;
    }

    .category-browse-side-panel {
        display: flex;
        flex-wrap: wrap;
        background: none;
        gap: 16pt;
        align-content: flex-start;
        border-right: none;
    }

    .category-parent-button {
        border: solid 1px #e5e5e5;
        background: var(--highlight);
        width: 100pt;
        height: 110pt;
        border-radius: 3pt;
        cursor: pointer;
    }

    .category-all {
        width: 200pt;
    }

    .category-browse-main:not(:empty) {
        border-left: solid 1px var(--primary);
    }

    .category-browse-main p {
        cursor: pointer;
    }

}
