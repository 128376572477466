
.calendar {
    width: 300px;
    border: solid 1px #ebeae9;
    border-radius: 10px;
    text-align: center;
}

.calendar-header {
    border-bottom: solid 1px #ebeae9;
    padding: 10px 0;
}

.calendar-header-top {
    display: flex;
    flex-direction: row;
    max-height: 30px;
    justify-content: space-between;
    align-items: center;

    color: #5a5a5d;
    font-size: 16px;
    font-weight: 700;
}

.calendar-header img {
    height: 20px;
    padding: 0 20px;
    cursor: pointer;
}

.calendar-header img.hide {
    visibility: hidden;
}


.calendar-header-bottom {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;

    color: #5a5a5d;
    font-size: 14px;
    font-weight: 700;
}

.calendar-header-bottom p {
    margin: 0;
}

.calendar-body {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    color: #838485;

    background-color: #FbFbFb;
    border-radius: 0 0 10px 10px;
}

.calendar-body p {
    margin: 2px 0;
    padding: 8px 0;
    font-size: 14px;
    font-weight: 700;

    cursor: pointer;
}

.calendar-date-inactive {
    color: #BBB;
}

.calendar-date-start p {
    background-color: var(--secondary);
    border-radius: 5px 0 0 5px;
    color: #fff;
}

.calendar-date-start-no-end p {
    background-color: var(--secondary);
    border-radius: 5px;
    color: #fff;
}

.calendar-date-end p {
    background-color: var(--secondary);
    border-radius: 0 5px 5px 0;
    color: #fff;
}

.calendar-date-highlight p {
    /*background-color: var(--secondary-muted);*/
    /*color: #fff;*/
    background-color: #ececec;
}

.calendar-date-not-month p {
    color: #ececec;
    cursor: default !important;
}

.calendar-date-inactive p {
    color: #ececec;
    cursor: default !important;
}

.calendar-date-highlight-hover p {
    background-color: #ececec;
    border-radius: 5px;
}

.calendar-date-highlight-hover-end p {
    background-color: #ececec;
    border-radius: 0 5px 5px 0;
}

.calendar-date-blocked-out p, .calendar-date-blocked-out-start p, .calendar-date-blocked-out-end p {
    background-color: #c4c4c4;
    color: #838485;
    cursor: default;
}

.calendar-date-blocked-out-start p {
    border-radius: 5px 0 0 5px;
    padding-right: 2px;
    margin-left: 2px;
}

.calendar-date-blocked-out-end p {
    border-radius: 0 5px 5px 0;
    padding-left: 2px;
    margin-right: 2px;
}
