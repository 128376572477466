
.mobile-nav-container {
    position: absolute;
    top: 0;
    left: -100vw;
    height: 100vh;
    width: 100vw;

    transition: left 0.3s ease;

    display: grid;
    grid-template-areas: "menu gap";
    grid-template-columns: 1fr 60pt;
    filter: drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.3));
}

.mobile-nav-container.right {
    left: 200vw;
    grid-template-areas: "gap menu";
    grid-template-columns: 60pt 1fr;

}

.mobile-nav-container.active {
    left: 0;
}

.mobile-nav-blank {
    grid-area: gap;
    background-color: rgba(255, 255, 255, 0);
}

.mobile-nav-menu {
    grid-area: menu;
    background-color: var(--background);
    overflow-y: scroll
}

.mobile-nav-overlay-hidden {
    display: none !important;
}

.nav-item-container {

}

.nav-item-text {
    border-bottom: var(--inactive) 1px solid;
    background-color: var(--highlight);
    margin: 0;
    padding: 10pt 0 4pt 8pt;
    font-size: 18px;
    font-weight: 600;
    color: #838485;
    cursor: pointer;
}

.nav-item-back {
    display: none;
}

.selected > .nav-buttons-container .nav-item-back {
    display: block;
}

.nav-buttons-container {
    display: flex;
    flex-direction: column;
}

.nav-item-container.selected {
}

.nav-item-container.selected .nav-item-text {
    padding-left: 20pt;
}

.nav-item-container.selected > .nav-buttons-container > .nav-item-text:not(.nav-item-back) {
    color: #4d4e50;
    padding-left: 10pt;
}

.nav-item-container.not-selected {
    display: none;
}

.nav-item-child-container {
    display: none;
}

.nav-item-container.selected > .nav-item-child-container {
    display: block;
}

.nav-buffer {
    height: 50vh;
}

.nav-close-button {
    padding: 10pt 8pt 10pt 8pt;
    font-size: 18px;
    font-weight: 600;
    color: #4d4e50;
    background-color: var(--highlight);
    border-bottom: var(--inactive) 1px solid;
}

.nav-drawer-account {
    background-color: var(--highlight);
    padding: 10pt 4pt;
    display: flex;
    gap: 4pt;
}

.nav-drawer-account img {
    height: 24pt;
}

.nav-drawer-account p {
    margin: auto 0;
    font-weight: 600;
    font-size: 18px;
    color: #4d4e50;
}


.header-nav-item-child-container {
    display: none;
}

.header-nav-item-container:hover .header-nav-item-child-container {
    position: absolute;
    display: block;
    background: var(--highlight);
}

.nav-tertiary {
    color: #838485;
    font-weight: bold;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
}

.nav-tertiary:first-child {
    margin-top: 0;
}

.nav-tertiary:last-child {
    margin-bottom: 30px;
}

.nav-tertiary.selected {
    color: #4D4E50;
}

.nav-tertiary:hover {
    color: #4D4E50;
}

.log-out-nav {
    padding-top: 40px;
}

.log-out-nav .nav-item-text {
    border-bottom: none;
    background: none;
    color: var(--secondary);
    text-decoration: underline;
    padding-bottom: 20px;
}

@media (min-width: 769px) {

    .nav-buffer {
        display: none;
    }

    .nav-close-button {
        display: none;
    }

    .nav-item-text {
        border-bottom: none;
        padding: 0;
        text-align: left;
        width: auto;
        height: 1.2em;
        font-family: Inter;
        font-weight: bold;
        color: #838485;
    }

    .on-page .nav-item-text {
        color: #4D4E50;
    }

    .nav-item-container:hover .nav-item-text {
        color: #4D4E50;
        transition: 0.3s;
    }

    .nav-item-child-container .nav-item-text {
        width: 160pt;
        max-width: 160pt;
        min-width: 160pt;
        font-size: 1rem;

    }

    .nav-item-child-container .nav-item-text {
        padding: 2pt 4pt;
        color: var(--text-main);
        border-bottom: solid 1px var(--primary);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .header-nav-container {
        display: flex;
        background: none;
        position: relative;
        gap: 30pt;
    }

    .nav-item-child-container {
        grid-template-columns: repeat(auto-fit, minmax(140pt, 240px));
    }

    .header-nav-container > .nav-item-container.selected > .nav-item-text {
        background: none;
        color: var(--primary);
    }

    .header-nav-container > .nav-item-container.selected > .nav-item-child-container {
        display: none;
    }

    .header-nav-container > .nav-item-container.hover > .nav-item-child-container {
    /*.header-nav-container > .nav-item-container > .nav-item-child-container {*/

        position: absolute;

        background: var(--highlight);
        filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.18));
        border: solid 1px #e5e5e5;
        border-radius: 2px;
        display: block;

        z-index: 1000;
    }

    .nav-item-container.selected > .nav-item-child-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 8pt;
        row-gap: 2pt;
        align-content: flex-start;
    }


    .header-nav-container > .nav-item-container > .nav-item-child-container > .nav-item-container.selected {
        display: grid;
        gap: 8pt;
        grid-template-columns: auto 1fr;
    }

    .header-nav-container > .nav-item-container.selected > .nav-item-child-container .nav-item-text {
        padding: 2pt 4pt;
    }

    .nav-buttons-container {
        gap: 2pt;
    }

    .secondary-nav {
        display: flex;
        background: none;
        position: relative;
        gap: 20pt;
        margin-top: 18px;
        min-height: 20px;
    }

    .secondary-nav .nav-item-text {
        font-size: 16px;
        color: #c4c4c4;
        z-index: 0;
    }

    .secondary-nav .on-page .nav-item-text {
        color: #4D4E50;
    }

}

