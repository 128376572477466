
.dialog-container-old {
    background: var(--background);
    padding: 10px 18px;
    border-radius: 12px;
    border: 1px solid #c4c4c4;
    width: 500px;
    margin-bottom: 200px;
}

.dialog-title-old {
    font-size: 24px;
    font-weight: 500;
    /*color: #838485;*/
    color: #5a5a5d;
    margin: 10px 0;
}

.dialog-message-old {
    font-size: 14px;
    color: #5a5a5d;
}

.dialog-action-container {
    display: flex;
    flex-direction: row-reverse;
    border-top: 1px solid #c4c4c4;
    padding-top: 12px;
    gap: 10px;
}

.dialog-action-container .button {
    max-width: 33%;
}

.dialog-action-container .dialog-neutral-button {
    background: none;
    color: var(--secondary);
    text-decoration: underline;
    min-width: 80px;
}

.dialog-negative-button {
    background: var(--warning);
}

.dialog-loader {
    /* Color */
    background-color: var(--secondary);

    /* Rounded border */
    border-radius: 9999px;

    /* Size */
    height: 14px;

    position: relative;
    overflow: hidden;

    margin: 20px 0 10px 0;
}

.dialog-loader-bar {
    /* Color */
    background: linear-gradient(to right, var(--secondary) 8%, #82d0ef 54%, var(--secondary) 86%);

    /* Rounded border */
    border-radius: 9999px;

    /* Absolute position */
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;

    /* Move the bar infinitely */
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: indeterminate-progress-bar;
}

@keyframes indeterminate-progress-bar {
    from {
        left: -100%;
    }
    to {
        left: 100%;
    }
}
