
.prod-detail-container {
    display: grid;
    grid-template-areas: "head head"
        "img highlight"
        "img input";
    grid-template-columns: 335px auto;
    grid-column-gap: 60px;
}

.prod-detail-head {
    grid-area: head;
    justify-self: start;
    text-align: left;
}

.prod-detail-value {
    font-weight: bold;
    font-size: 21px;
    color: #c4c4c4;
    margin: 0 0 10px 0;
}

.prod-detail-name {
    font-weight: bold;
    font-size: 43px;
    color: #5a5a5d;
    margin: 0 0 10px 0;
}

.prod-detail-uom {
    font-size: 24px;
    color: #5a5a5d;
    font-weight: 300;
    margin: 0 0 20px 0;
}

.prod-detail-img-container {
    grid-area: img;
    width: 335px;
}

.prod-detail-container img {
    max-width: 100%;
    width: 100%;
}

.prod-detail-highlight {
    grid-area: highlight;
    text-align: left;
}

.prod-detail-input {
    grid-area: input;
    justify-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.prod-detail-input .prod-input-divider {
    display: none;
}

.prod-detail-input div:empty {
    display: none;
}

.prod-detail-input {
    text-align: right;
}

.prod-detail-price-pre-promo {
    font-size: 26px;
    color: #c4c4c4;
    margin: 0 0 5px 0;
    text-decoration: line-through;
}

.prod-detail-price {
    font-size: 32px;
    font-weight: bold;
    color: #5a5a5d;
    margin: 0 0 5px 0;
}

.prod-detail-price-tax {
    font-size: 22px;
    color: #c4c4c4;
    font-weight: 300;
    margin: 0;
}

.prod-detail-container .prod-input {
    margin-top: 10px;
}

.prod-branding-container {
    border-top: #e5e5e5 solid 2px;
    margin: 40px 0;
    text-align: left;
    padding-top: 20px;
}

.prod-detail-branding-container {
    border-top: solid 2px #e5e5e5;
    padding-top: 25px;
    padding-bottom: 62px;
    margin-top: 40px;
}

.prod-detail-branding-container p {
    max-width: 600px;
}

@media (max-width: 768px) {
    .prod-detail-container {
        display: flex;
        flex-direction: column;
    }

    .prod-detail-img-container {
        width: auto;
        max-width: 400px;
        margin: 20px auto 40px auto;
    }

    .prod-detail-input {
        margin-top: 20px;
        text-align: left;
    }
}
