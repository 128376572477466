
.dialog-container {
    background: var(--background);
    padding: 10px 18px;
    border-radius: 6px;
    border: 1px solid #c4c4c4;
    width: 400px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
}

.dialog-title {
    font-size: 18px;
    font-weight: bold;
    color: #4d4e50;
    margin: 10px 0 10px 0;
    order: 1;
}

.dialog-message {
    font-size: 16px;
    color: #4d4e50;
    order: 1;
    margin: 0 0 10px 0;
}

.dialog-button-container {
    order: 3;
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    border-top: solid 1px #c4c4c4;
    gap: 8px;
}

.dialog-button-container .dialog-button-wrapper {
    width: 140px;
    min-height: 40px;
}

.dialog-container > .dialog-button-wrapper {
    order: 3;
    padding: 0 8px;
    margin-bottom: 8px;
}

.dialog-button-wrapper .button {
    font-size: 14px;
    padding: 8px 6px;
}

.dialog-button-wrapper {

}

.dialog-container > .dialog-button-wrapper:first-of-type {
    padding-top: 12px;
    border-top: solid 1px #c4c4c4;
}


.button.dialog-negative {
    border: var(--warning) 1px solid;
    background: var(--warning);
    color: #fff;
    height: 100%;
}

.button.dialog-neutral {
    border: #c4c4c4 1px solid;
    color: #4d4e50;
    background: none;
    height: 100%;
}
.button.dialog-positive {
    border: var(--primary);
    background: var(--primary);
    color: #fff;
    height: 100%;
}

.dialog-loader {
    /* Color */
    background-color: var(--secondary);

    /* Rounded border */
    border-radius: 9999px;

    /* Size */
    height: 14px;

    position: relative;
    overflow: hidden;

    margin: 20px 0 10px 0;
}

.dialog-loader-bar {
    /* Color */
    background: linear-gradient(to right, var(--secondary) 8%, #82d0ef 54%, var(--secondary) 86%);

    /* Rounded border */
    border-radius: 9999px;

    /* Absolute position */
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;

    /* Move the bar infinitely */
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: indeterminate-progress-bar;
}

.loading-dialog-content {
    display: flex;
    align-items: center;
    gap: 16px;
}

.loading-dialog-title {
    color: #4d4e50;
    font-weight: 700;
}

@keyframes indeterminate-progress-bar {
    from {
        left: -100%;
    }
    to {
        left: 100%;
    }
}

@media (max-width: 768px) {

    .dialog-container {
        margin: 0 20px 0;
    }

}
