
.acct-layout {
    max-width: 1280px;
    width: min(1280px, calc(100vw - 60pt));
    margin: 0 auto;

    display: grid;
    grid-template-columns: 242px 1fr;
    grid-template-rows: auto auto 1fr;
    column-gap: 19px;
}

.acct-layout .breadcrumb-container {
    grid-column: span 2;
    grid-row: 1;
}

.acct-layout .filter-container {
    grid-column: 1;
    margin-bottom: 50px;
    align-self: start;
}

.acct-layout-loading {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.acct-content {
    display: flex;
    flex-direction: column;
    grid-column: 2;
    grid-row: 2 / span 2;
    text-align: left;
    margin-bottom: 100px;
    overflow-x: hidden;
}

.acct-content .pagination-strip {
    margin-top: auto;
    padding-top: 30px;
}

.acct-content.max-width-set .pagination-strip {
    max-width: 600px;
}

.acct-h1 {
    color: #838485;
    font-weight: bold;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 20px;
}

.acct-h2 {
    font-size: 18px;
    font-weight: bold;
    color: #4D4E50;
    margin-top: 0;
    margin-bottom: 6px;
}

.acct-h4 {
    color: #838485;
    font-size: 18px;
    margin-top: 0;
}

.acct-val {
    color: #4D4E50;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 18px;
    max-width: 600px;
    text-align: justify;
}

.acct-content .button {
    max-width: 600px;
}

.acct-content .input {
    border: solid 1px #c4c4c4;
    margin-bottom: 18px;
}
.acct-content .input:focus {
    border: solid 2px var(--secondary);
    margin-bottom: 18px;
}

.acct-content .card-detail-input {
    max-width: 600px;
}

.acct-content .card-detail-input .input {
    margin-bottom: 0;
}

.acct-line {
    border-bottom: solid 1px #c4c4c4;
    padding: 0 10px 10px 10px;
    max-width: 600px;
}
.acct-line:last-child {
    border-bottom: none;
}

.acct-line:first-of-type .acct-line-h1:first-child {
    margin-top: 0;
}

/*.acct-stripy:nth-child(2n) {*/
/*    background: #ececec;*/
/*}*/

.acct-line-h1 {
    font-weight: 600;
    color: #5A5A5D;
    margin: 24px 0 4px 0;
}

.acct-line-h2 {
    font-weight: 600;
    color: #c4c4c4;
    margin: 4px 0 6px 0;
}

.acct-line-h3 {
    font-weight: 600;
    color: #5A5A5D;
    margin: 0;
}

.acct-line-info {
    display: grid;
}

.acct-tertiary-nav {
    padding-right: 10px;
}

@media (max-width: 768px) {

    /*.acct-layout {*/
    /*    max-width: 100%;*/
    /*    width: 100%;*/
    /*    display: grid;*/
    /*    grid-template-columns: 1fr;*/
    /*    padding: 0 20px;*/
    /*    margin: 0;*/
    /*    column-gap: 0;*/
    /*    box-sizing: border-box;*/
    /*}*/

    .acct-layout {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        margin: 0;
        column-gap: 0;
        box-sizing: border-box;
    }

    .acct-layout .breadcrumb-container {
        order: -2;
    }

    .acct-tertiary-nav {
        order: -2;
    }

    .acct-layout .filter-container {
        order: -1;
    }

    .acct-content {
        grid-column: 1;
        grid-row: 3;
    }

    .acct-content .input-form {
        display: flex;
        flex-direction: column;
    }

}
