
.input-form {
    max-width: 1280px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    column-gap: 80px;
}

.input-form-heading {
    color: #838485;
    font-weight: 600;
    font-size: 24px;
    margin: 18px 0 6px 0;
}

.input-form-sub-heading {
    color: #c4c4c4;
    font-weight: 500;
    font-size: 18px;
    margin: 18px 0 0 0;
}

.input-form-text {
    color: #838485;
    margin: 2px 0 0 0;
}

.input-form-value {
    color: #838485;
    font-weight: bold;
    font-size: 22px;
    margin: 0;
}

.input-form-img {
    margin-top: 8px;
}

.input-form-item .selector-container {
    height: auto;
    margin-top: 4px;
}

.input-form-item .selector-container {
    border: solid 1px #c4c4c4;
    border-radius: 10px;
}

.input-form-item select {
    font-size: 16px;
    font-weight: 500;
    color: #838485;
}

.input-form .input {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    background-color: white;
    width: 70%;
    margin: 2px 0 0 0;
    font-size: 16px;
    padding: 6px 8px;
}

.input-form .input:focus {
    border: 1px solid var(--secondary);
    margin: 2px 0 0 0;
}

.form-checkbox {
    display: flex;
    gap: 12px;
    align-items: center;
    margin: 18px 0 8px 0;
}

.form-checkbox .checkbox-container {
    flex-shrink: 0;
}

.form-checkbox p {
    font-size: 18px;
    font-weight: 500;
    color: #838485;
    margin: 0;
}

.form-checkbox .checkbox-container {
    outline: 1px solid #c4c4c4;
    background-color: white;
}

.input-form-warning{
    font-size: 16px;
    color: var(--warning);
    font-weight: 600;
}

.input-form-upload {
    font-size: 16px;
    color: var(--secondary);
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
}

.input-form-upload input[type="file"] {
    display: none;
}

.input-form-item .date-picker-container {
    margin: 8px 2px 0;
}

.input-form .clickable-text {
    font-size: 16px;
    color: var(--secondary);
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
    margin: 0;
}

.input-form textarea.input {
    width: 100%;
}

@media (max-width: 768px) {
    .input-form {
        margin-bottom: 46px;
    }
}
