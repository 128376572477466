
.sign-in-page-content {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 100px;
}

.sign-in-or {
    background: var(--secondary);
    border-radius: 50%;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 12px;
}

.sign-in-or p {
    margin: auto auto;
    font-weight: bold;
    color: white;
    font-size: 19px;
}


@media (max-width: 768px) {

    .sign-in-page-content {
        flex-direction: column;
    }

    .sign-in-or {
        align-self: center;
        margin: 40px 0;
    }

}



