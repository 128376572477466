
.prod-tile-compact {
    width: 100%;
}

.prod-tile-compact .prod-tile {
    width: 100%;
}

.prod-tile-compact .prod-tile-main-content {
    display: grid;
    grid-template-areas: "img cost input"
        "img cost input"
        "more cost input";
    grid-template-columns: 140px auto 240px;
    column-gap: 10px;
}

.prod-tile-compact .prod-img-container {
    grid-area: img;
    align-self: center;
}
.prod-tile-compact .prod-purchase-info {
    grid-area: cost;
}

.prod-tile-compact .prod-input {
    grid-area: input;
    align-self: flex-end;
}

.prod-tile-compact .prod-more-details {
    grid-area: more;
    align-self: flex-end;
}

.prod-tile-compact .prod-input-divider{
    display: none;
}

.prod-tile-compact .prod-input-quantity-container {
    margin-bottom: 0;
}

@media (max-width:768px) {

    /*.prod-tile-compact .prod-tile-main-content {*/
    /*    display: grid;*/
    /*    grid-template-areas: "img cost input"*/
    /*    "img cost input"*/
    /*    "more more more";*/
    /*    grid-template-columns: 90px auto 130px;*/
    /*    column-gap: 8px;*/
    /*    margin: 10px 12px 15px 12px;*/
    /*}*/

    .prod-tile-compact .prod-tile-main-content {
        display: grid;
        grid-template-areas: "img cost"
        "img input"
        "more more";
        grid-template-columns: 90px 220px;
        column-gap: 8px;
        margin: 10px 12px 15px 12px;
    }

}
