
.header-detail {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.header-link {
    margin: 16px 0 8px;
    font-size: 14px;
    font-weight: 600 !important;
}

.header-actions {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}


@media (min-width: 769px) {

    .header-detail > div {
        max-width: 33%;
    }
}

@media (max-width: 768px) {
    .header-detail {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;
    }

    .header-detail .num {
        text-align: left;
    }
}
