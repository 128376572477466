
.user-container {
    text-align: left;
    border-bottom: solid 1px #c4c4c4;
    max-width: 600px;
    color: #838485;
    font-weight: 500;
    padding: 0 8px 0 8px;
    margin-top: 20px;
}

.user-container:last-child {
    border-bottom: none;
    margin-bottom: 100px;
}

.user-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 300px));
}

.user-header {
    grid-column: span 2;
    color: #c4c4c4;
    margin: 12px 0 0 0;
}

.user-text {
    margin: 2px 0 0 0;
}

.user-bold {
    font-weight: bold;
}

.user-container .clickable-text {
    color: var(--secondary);
    font-weight: 600;
    margin: 6px 0 12px 0;
}

.user-options {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 22px;
}

.clickable-text.warning {
    color: var(--warning);
}
