
.leaderboard {
    width: 1280px;
    height: 90px;
    margin: 40px 0;
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

.skyscraper {
    height: 600px;
    width: 240px;
    margin: 20px 0;
    background-size: cover;
    background-position: center;
    cursor: pointer;
}


@media (max-width: 768px) {

    .leaderboard {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .leaderboard img {
        max-width: 100%;
    }


}