
.banner-selector-container {
    display: flex;
    max-width: 600px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.banner-option {
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #838485;
}

.banner-option p {
    margin: 5px 0 0 0;
}

.banner-option.selected {
    color: var(--secondary);
    text-decoration: underline;
}

.banner-option svg {
    width: 160px;
    height: auto;
}

.banner-preview svg {
    max-width: 600px;
    height: auto;
}

.banner-mobile-preview svg {
    display: flex;
    width: 300px;
    height: auto;
    margin: 0 auto;
}

.banner-product-range {
    margin-top: 12px;
}

.banner-product-range p {
    margin: 2px 0;
    font-weight: 600;
    color: #838485;;
}

.banner-product-range .clickable-text {
    font-size: 14px;
    margin-left: 6px;
}
