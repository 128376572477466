
.count-down-clock {
    display: flex;
    text-align: center;
    align-items: baseline;
    align-self: center;
}

.count-down-number {
    font-size: 48px;
    font-weight: 600;
    color: var(--secondary);
    margin: 0;
}

.count-down-unit {
    font-size: 28px;
    color: #c4c4c4;
    font-weight: 300;
    margin: 0;
}

.count-down-spacer {
    width: 14px;
}
