
.placed-order-tile {
    max-width: 600px;
}

.account-tile-header-container {
    display: flex;
    gap: 10pt;
    padding: 6pt 6pt;
    font-size: 0.9em;
}

.account-tile-header-container .clickable-text {
    margin: auto 0 0 0;
}

.account-tile-header-detail {
    display: grid;
    grid-template-columns: repeat(auto-fill, 1fr);
    margin-right: auto;
    text-align: left;
    column-gap: 14pt;
    row-gap: 4pt;
}

.account-tile-h {
    grid-row-start: 1;
    margin: 0;
}

.account-tile-b {
    grid-row-start: 2;
    margin: 0;
    font-weight: 500;
}

.account-tile-body {
    display: grid;
    grid-template-areas: "content empty action";
    grid-template-columns: 1fr 100pt auto;
    padding: 4pt 6pt;
    min-height: 60px;
}

.account-tile-content {
    grid-area: content;
}

.account-tile-content-h {
    margin: 0;
    font-size: 0.9em;
    text-align: left;
}

.account-tile-content-b {
    font-weight: 500;
    margin: 1pt 0 6pt 0;
    text-align: left;
}

.account-tile-content .info {
    margin: 2pt 0 4pt 0;
}

.account-tile-action {
    grid-area: action;
    display: flex;
    align-items: flex-end;
    gap: 12px;
    padding-bottom: 6px;
    padding-right: 6px;
}

.placed-order-line-header {
    display: flex;
    font-size: 0.9em;
    gap: 10pt;
    padding: 0 6pt;
}

.placed-order-line-header p {
    margin-top: 6pt;
    margin-bottom: 6pt;
}

.placed-order-line-body {
    display: grid;
    grid-template-columns: 70px auto 100px 100px;
    max-width: 600px;
    padding: 8pt 6pt;
    border-bottom: solid 1px #c4c4c4;
}

.placed-order-line-img {
    grid-area: img;
}

.placed-order-line-img img {
    position: relative;
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
}

.placed-order-line-actions {
    grid-area: action;
    display: flex;
    flex-direction: column-reverse;
}

.placed-order-line-details {
    grid-area: details;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10pt, 110pt));
    margin-right: auto;
    text-align: left;
    column-gap: 10pt;
    row-gap: 6pt;
}

.placed-order-line-details p {
    margin: 0;
}

.placed-order-detail-h {
    font-size: 14px;
    font-weight: bold;
    color: #c4c4c4;
    margin: 0 0 4px 0;
}

.placed-order-detail-b {
    font-weight: bold;
    color: #5a5a5d;
    margin-top: 0;
}

.placed-order-line-info {
    grid-area: info;
}

.placed-order-line-info .info {
    margin: 0;
}

.shipment-tile-body {
    display: grid;
    grid-template-areas:
            "content empty map"
            "action empty map";
    grid-template-columns: 1fr 100pt 200pt;
    grid-template-rows: 1fr auto;
    padding: 4pt 6pt;
}

.shipment-tile-body .button {
    max-width: 140pt;
}

.delivery-tile-map-container {
    grid-area: map;
}

.shipment-tile {
    max-width: 600px;
}

.account-compact-header {
    border-bottom: var(--primary) 1px solid;
    display: flex;
    gap: 10pt;
    padding: 6pt 6pt;
}

.account-compact-header .button {
    min-width: 140pt;
}

.account-links-popup {
    color: var(--text-main);
    display: none;
    position: absolute;
    transform: translateX(-80pt);
    width: 400pt;
    cursor: auto;
    z-index: 100;
}

.account-links-popup.visible {
    display: block;
}

.account-link-empty {
    border-bottom: var(--primary) 1px solid;
    display: flex;
    gap: 10pt;
    padding: 6pt 6pt;
    margin: 0!important;
}

.account-compact-header p {
    margin: 0;
}

.account-ordering-link-loading {
    height: 2.4em;
    width: 300pt;
    margin-bottom: 10pt;
}

.account-tile-loading {

}

.back-order-line {
    max-width: 600px;
}

.back-order-line-header p {
    font-size: 16px;
    font-weight: bold;
    color: #5A5A5D;
    margin: 4px 0;
}

.back-order-line-body {
    padding: 10px;
    display: grid;
    grid-template-columns: 60px 2fr 100px 1fr 1fr 80px;
    gap: 12px;
    border-bottom: solid 1px #c4c4c4;
}

.back-order-button-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    grid-column: 6;
}

.back-order-button-container .button {
    width: 80px;
}

.back-order-line-body .placed-order-line-img {
    grid-area: revert;
}

@media (max-width: 768px) {

    .account-tile-header-container {
        flex-wrap: wrap;
    }

    .account-tile-body {
        grid-template-areas: "content"
            "action";
        grid-template-columns: 1fr;
        gap: 8pt;
    }


    .placed-order-line-body {
        grid-template-areas: "img details"
            "img info"
            "action action";
        grid-template-columns: 80pt auto;
    }

    .placed-order-line-img img {
        height: 78pt;
        width: 78pt;
    }

    .placed-order-line-details {
        grid-template-columns: repeat(auto-fill, auto);
    }

    .account-compact-header .button {
        width: 100%;
    }

    .account-order-detail-buttons .button {
        width: 100%;
    }

    .account-order-detail-buttons {
        flex-direction: column;
    }

    .account-links-popup {
        width: auto;
        max-width: 80vw;
        transform: translateX(-70%);
    }

}
