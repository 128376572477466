.brand-bank-accordion-header {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    /*border-bottom: solid 1px #e5e5e5;*/
    padding-bottom: 10px;
    margin: 10px 0;
}

.brand-bank-accordion-header p {
    font-weight: bold;
    color: #5a5a5d;
    font-size: 24px;
    margin: 0;
}

.brand-bank-arrow-container {
    transition: transform 0.3s;
    background-color: #ebeae9;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
}

.expanded .brand-bank-arrow-container{
    transform: rotate(0deg);
}

.brand-bank-accordion-header img {
    margin-top: 2px;
    width: 15px;
}

.brand-bank-content-container {
    text-align: left;
    padding-bottom: 22px;
}