
.password-reset-content {
    max-width: 1280px;
    margin: 0 auto;
    text-align: left;
    min-height: 600px;
    padding-top: 20px;
}

.password-reset-content-format {
    max-width: 660px;
}

.password-reset-content-format h1 {
    margin: 30px 0;
}

.password-reset-content-format .input-container {
    margin-bottom: 10px;
}

.password-reset-content-format .button {
    margin-bottom: 30px;
}

.password-reset-content p {
    margin: 6px 0;
}