
.filter-container {
    width: 240px;
    border: solid 1px #EBEAE9;
    border-radius: 10px;
    background: #fff;
}

.filter-header {
    display: flex;
    height: 32px;
    background: #EBEAE9;
    font-weight: bold;
    font-size: 13px;
    align-items: center;
    border-radius: 9px 9px 0 0;
    padding: 0 10px;
}

.filter-header img {
    height: 13px;
    margin-left: 10px;
}

.filter-item-header {
    font-weight: bold;
    font-size: 11px;
}

.filter-content {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    min-height: 300px;
}

.filter-group-head {
    display: flex;
    margin: 10px 0;
    cursor: pointer;
    font-weight: bold;
    font-size: 11px;
}

.filter-group-head img {
    transition: transform 0.3s;
    height: 9px;
    margin: auto 5px auto 0;
}

.filter-group.collapsed img {
    transform: rotate(180deg);
}

.filter-item {
    display: flex;
    font-weight: normal;
    font-size: 11px;
    margin-bottom: 10px;
    cursor: pointer;
}

.filter-item img {
    height: 16px;
    margin: auto 5px auto 0;
}

.filter-item p {
    margin: 0;
}

.filter-group-div {
    height: 1px;
    background: #EBEAE9;
}

.filter-group .collapse-div {

}

.filter-update-container {
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
    margin-top: auto;
    padding-top: 10px;
    margin-bottom: 5px;
}

.filter-update-container p {
    font-weight: bold;
    font-size: 15px;
    color: var(--secondary);
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
}

.filter-date-range {
    flex-direction: column;
    text-align: left;
}

.filter-date-range .range-date-picker {
    flex-wrap: nowrap;
}

.filter-date-range p {
    margin-bottom: 4px;
}

.filter-date-range .date-picker-container div input {
    font-size: 11px;
    padding: 4px 0;
    width: 100%;
}

.filter-date-range .react-datepicker-wrapper {
    margin-left: 7px;
    margin-right: 1px;
}
.filter-date-range .react-datepicker-wrapper:first-child {
    margin-right: 7px;
    margin-left: 1px;
}

/*============ MOBILE MENU ============*/

.mobile-nav-menu .filter-container {
    border-radius: 0;
    border: none;
}

.mobile-nav-menu .filter-header {
    border-radius: 0;
}

@media (max-width: 1080px) {

    .filter-container {
        width: 100%;
    }

    .filter-content {
        min-height: 0;
    }

    .filter-content {
        display: grid;
    }

}
