
.pagination-strip {
    display: flex;
    margin: 60px 0;
    justify-content: center;
}

.pagination-arrow {
    max-height: 18px;
    height: 18px;
    padding: 2px 0;
    margin: 0 25px;
    cursor: pointer;
}

.pagination-item {
    font-weight: bold;
    font-size: 16px;
    color: #5a5a5d;
    padding: 2px 0;

    height: 18px;
    margin: 0 10px;
}

.pagination-item.selected {
    color: #fff;
    background: #5a5a5d;
    padding: 2px 6px;
    margin: 0 4px;
    border-radius: 4px;
}

.pagination-link {
    cursor: pointer;
}

.form-pagination {
    width: 600px;
    padding-left: 0 !important;
}