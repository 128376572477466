
.detail-line {
    display: grid;
    grid-template-columns: 80px 1fr 120px 120px;
    max-width: 800px;
}

.detail-line .acct-line-h2,
.invoice-payment-line .acct-line-h2  {
    padding: 0 8px;
}

.detail-line .acct-line-h1,
.invoice-payment-line .acct-line-h1  {
    margin: 0;
    padding: 4px 8px;
}

.num {
    text-align: right;
}

.detail-line .line-highlight {
    background: #ececec;
}

.invoice-payment-line {
    display: grid;
    grid-template-columns: 150px 130px;
}

.order-images {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    gap: 12px;
}

.order-images img {
    max-width: 394px;
}

@media (max-width: 768px) {
    .detail-line {
        grid-template-columns: 40px 1fr minmax(0,80px) auto;
    }

    .detail-line .acct-line-h1 {
        font-size: 14px;
    }

    .detail-line .acct-line-h1 {
        font-size: 14px;
    }
}
