
.page-content {
    max-width: 1280px;
    width: min(1280px, calc(100vw - 60pt));
    margin: 0 auto;
}

.page-content-buffer {
    padding: 30px 0;
}

.page-min-height {
    min-height: 600px;
}

.page-readable-width {
    text-align: left;
    max-width: 600px;
}

.page-readable-width-large {
    text-align: left;
    max-width: 800px;
}


.page-h1 {
    font-weight: bold;
    font-size: 43px;
    color: #5a5a5d;
    margin: 0 0 10px 0;
}

.page-h2 {
    font-weight: bold;
    font-size: 28px;
    color: #5a5a5d;
    margin: 0 0 10px 0;
}

.page-h3 {
    font-weight: bold;
    font-size: 28px;
    color: #c4c4c4;
    margin: 0 0 10px 0;
}

.page-h4 {
    font-weight: bold;
    font-size: 22px;
    color: #5a5a5d;
    margin: 0 0 10px 0;
}

.page-h5 {
    font-weight: bold;
    font-size: 22px;
    color: #c4c4c4;
    margin: 0 0 10px 0;
}



@media (max-width: 768px) {

    .page-content {
        width: min(1280px, calc(100vw - 40px));
    }

}

.grecaptcha-badge {
    visibility: hidden !important;
}

