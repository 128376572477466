
.footer {
    background-color: var(--footer-colour);
    padding-top: 30pt;
    min-height: 300pt;
}

.footer-item-container, .footer-socials, .footer-credits-container {
    max-width: 960pt;
    width: min(960pt, calc(100vw - 60pt));
    margin-left: auto;
    margin-right: auto;
    padding-left: 30pt;
    padding-right: 30pt;
    box-sizing: border-box;
    flex-wrap: wrap;
    row-gap: 30pt;
}

.footer-item-container {
    display: flex;
}

.footer-credits-container {
    margin-bottom: 40pt;
}

.footer-highlight {
    font-weight: bold;
}

.footer-items {
    text-align: left;
    flex-grow: 1;
    min-width: 300px;
}

.footer h1 {
    font-size: 14pt;
    margin: 0 0 20pt 0;
    font-family: Inter;
    font-weight: bold;
    color: var(--highlight);
    text-align: left;
}

.footer p {
    cursor: pointer;
    font-size: 12pt;
    margin: 8px 0 0 0;
    color: #EBE9E9;
    font-family: Inter;
    font-weight: 300;
    text-align: left;
}

.footer-items p:hover, .footer-socials p:hover {
    color: #ffffff;
}

.footer-socials {
    margin-top: 40px;
}

.footer-socials img {
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.social-link {
    display: flex;
    margin-top: 8px;
}

.social-link p {
    margin: auto 10pt;
}

.footer-credits-container {
    margin-top: 40pt;
    padding-top: 20pt;
    border-top: 2px solid #A2A2A2;
}

.footer-credits-container p {
    cursor: revert;
    font-size: 10pt;
    margin: 2pt 0;
}

.footer-credits {
    font-weight: 500;
    color: var(--highlight);
}


@media (max-width: 768px) {

    .footer-item-container {
        width: auto;
        display: flex;
        flex-direction: column;

        padding: 0 20pt;
        gap: 30pt;
    }

    .footer-socials {
        padding: 0 20pt;
        margin: 40pt 0 0 0;
    }

    .footer-credits-container {
        width: auto;
        margin: 40pt 8pt 0 8pt;
        padding: 20pt 12pt;
    }

}