
.address-options-header {
    font-size: 1.2em;
    font-weight: bold;
}

.address-name {
    font-weight: bold;
}

.address-options-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40pt;
    row-gap: 10pt;
    margin-top: 8pt;
    justify-content: space-between;
}

.address {
    display: flex;
    flex-direction: column;
    gap: 2pt;
}

.address-select-options {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 2pt;
}

.address-select {
    display: flex;
    flex-direction: column;
    gap: 6pt;
}

.address-select .button {
    margin-left: 0;
    padding: 6pt 8pt;
    min-width: 200pt;
}

.input-address p {
    margin-top: 4pt;
}

.input-address .button {
    margin-top: 4pt;
}

.add-address-button {
    color: var(--secondary);
    text-underline: var(--secondary);
    font-weight: bold;
    margin-top: auto;
}
