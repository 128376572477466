
.about-body {
    max-width: 1280px;
    margin: 0 auto;
    padding-bottom: 20pt;
    text-align: left;
}

.about-body h1,
.about-body h2,
.about-body p {
    max-width: 600px;
}

.about-body h1 {
    text-transform: uppercase;
    font-weight: bold;
    color: #5a5a5d;
    font-size: 24px;
}

.about-body h2 {
    font-weight: bold;
    font-size: 43px;
    color: #5a5a5d;
}

.about-body p {
    color: #5a5a5d;
}



@media (max-width: 768px) {

    .about-body {
        padding: 0 8pt;
    }

}
