
.sign-in-banner {
    width: 100%;
    display: flex;
    height: 120px;
    background: #D3EDF4;
    color: #57575A;
    font-weight: bold;
    font-size: 19px;
    justify-content: center;
    align-items: center;
    gap: 18px;
}

.sign-in-banner .button {
    font-size: 18px;
}


@media (max-width: 768px) {

    .sign-in-banner{
        min-height: 120px;
        height: auto;
        flex-direction: column;
        padding: 30px 10px;
        box-sizing: border-box;
        gap: 16px;
    }

    .sign-in-banner p {
        margin: 0;
    }

    .sign-in-banner .button {
        min-width: 200px;
    }


}

