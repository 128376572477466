
.leaflet-pane {
    z-index: 0 !important;
}

.leaflet-control {
    z-index: 0 !important;
}

.delivery-tracker-header {
    margin-top: 10px;
    height: 575px;
}

.partner-home-layout {
    min-height: 900px;
    padding-bottom: 140px;
}
