
.brand-head-and-text {
    margin-bottom: 20px;
}

.brand-header {
    font-weight: bold;
    color: #5a5a5d;
    font-size: 17px;
    margin: 0;
}

.brand-text {
    color: #5a5a5d;
    margin: 0;
    font-size: 17px;
}

.brand-highlight-line {
    margin-bottom: 4px;
    margin-top: 14px;
}

.brand-table-item, .brand-table-item-three {
    display: flex;
    margin-top: 22px;
    margin-bottom: 4px;
}

.brand-table-item-tag {
    color: #5a5a5d;
    width: 200px;
    font-weight: bold;
    margin: 0;
}

.brand-table-item-value {
    color: #5a5a5d;
    margin: 0;
}

.brand-table-item + .brand-table-item,
.brand-table-item-three + .brand-table-item-three,
.brand-highlight-line + .brand-highlight-line {
    margin-top: 0;
}

:first-child.brand-table-item,
:first-child.brand-table-item-three,
:first-child.brand-highlight-line {
    margin-top: 0;
}

.prod-detail-highlight .brand-head-and-text > :first-child {
    margin-bottom: 20px;
    font-size: 24px;
}

.prod-detail-highlight .brand-text {
    font-size: 19px;
}