
.unpaid-invoice-container {
    width: min(100%, 600px);
    border-bottom: solid 1px #c4c4c4;
    text-align: left;
    padding: 0 10px;
    box-sizing: border-box;
}

.unpaid-invoice-container:last-child {
    border-bottom: none;
}

.unpaid-invoice-vals {
    display: flex;
    justify-content: space-between;
}

.inv-v.r, .inv-line-grid .r, .unpaid-invoice-container .r {
    text-align: right;
}

.unpaid-invoice-container .clickable-text{
    font-weight: 600;
    color: var(--secondary);
    margin: 16px 0 18px 0;
    font-size: 14px;
}

.inv-h {
    font-weight: 600;
    color: #c4c4c4;
    margin: 4px 0 6px 0;
}

.inv-v {
    font-weight: 600;
    color: #5a5a5d;
    margin: 0;
}

.invoice-detail-container {
    text-align: left;
}

.inv-line-grid {
    display: grid;
    grid-template-columns: 80px auto 80px 100px;
    width: min(100%, 600px);
    max-width: 600px;
    margin-bottom: 40px;
}

.inv-line-grid.payment{
    grid-template-columns: 160px auto;
}

.inv-line-grid .inv-v {
    padding: 3px 0;
}

.inv-line-grid.product .inv-v:nth-child(8n - 7),
.inv-line-grid.product .inv-v:nth-child(8n - 6),
.inv-line-grid.product .inv-v:nth-child(8n - 5),
.inv-line-grid.product .inv-v:nth-child(8n - 4) {
    background: #ececec;
}

.inv-line-grid.payment .inv-v:nth-child(4n - 3),
.inv-line-grid.payment .inv-v:nth-child(4n - 2) {
    background: #ececec;
}

.inv-h1 {
    font-weight: 600;
    color: #5a5a5d;
    margin: 24px 0 4px 0;
}

.inv-summary-container {
    text-align: left;
}

.inv-summary-h {
    margin: 28px 0 6px 0;
    font-weight: 600;
    color: #5a5a5d;
}

.inv-summary-info {
    display: grid;
    grid-template-columns: 100px 100px;
}

.inv-payment-container {
    text-align: left;
    border-bottom: solid 1px #c4c4c4;
    width: min(100%, 600px);
    padding: 0 10px;
    box-sizing: border-box;
}

.inv-payment-container:last-child {
    border-bottom: none;
}

.inv-payment-info {
    display: grid;
    grid-template-columns: 140px 140px;
}

.inv-allocations {
    display: flex;
}

.inv-allocations p {
    width: 140px;
    font-weight: 600;
    margin: 8px 0 6px 0;
    font-size: 14px;
    color: #c4c4c4;
}

p.inv-alloc-display-amnt  {
    color: #5a5a5d;
    text-align: right;
    width: 100px;
}

p.inv-alloc-display-cr {
    margin-left: 24px;
    color: #5a5a5d;
}

.inv-allocations .clickable-text {
    color: var(--secondary);
    width: 220px;
    padding: 0 8px;
}

p.inv-alloc-allocated {
    width: 60px;
}

.inv-allocations:nth-child(2n+1) {
    background-color: #ececec;
}

@media (max-width: 768px) {
    .unpaid-invoice-vals {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 24px;
        row-gap: 8px;
    }
}


