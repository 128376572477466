
.help-desk-request-create-container {
    max-width: 600px;
    text-align: left;
}

.help-desk-request-create-container h1 {
    font-weight: bold;
    font-size: 43px;
    color: #5a5a5d;
}

.help-desk-request-create-container p {
    color: #5a5a5d;
}

.help-desk-request-create-container textarea {
    width: 100%;
    margin-bottom: 8px;
    min-height: 160px;
}

.help-desk-request-create-container textarea:focus {
    margin-bottom: 7px;
}

