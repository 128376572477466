
.report-container {
    background-color: var(--background);
    padding: 8pt 12pt;
    border-radius: 2pt;
    border: solid 1px #e5e5e5;
    max-width: 424pt;
    box-sizing: border-box;
}

.report-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12pt;
}

.error-back-button {
    grid-column: 2;
}


@media (max-width: 768px) {

    .report-container {
        max-width: calc(100vw - 16pt);
    }

}
