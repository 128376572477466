
.product-content {
    width: min(1280px, calc(100vw - 60pt));
}

.product-content .breadcrumb-container {
    grid-area: breadcrumb;
}

.product-content .pagination-strip {
    grid-area: page-nav;
}

.sub-category-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2px 0;
    margin: 40px 0;
}

.sub-category-container p {
    font-weight: bold;
    color: #838485;
    padding: 2px 12px;
    border-left: solid 1px #c4c4c4;
    margin: 0;
}

.sub-category-container p:first-child {
    border-left: none;
    padding-left: 0;
}

.sub-category-container p:hover {
    color: #4D4E50;
    cursor: pointer;
}


@media (max-width:768px) {

    .product-content {
        width: min(1280px, calc(100vw - 40px));
    }

}


