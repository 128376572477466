
.product-layout {
    display: flex;
    gap: 19px;
    align-items: flex-start;
}

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 240px);
    flex-grow: 1;
    grid-row-gap: 19px;
    grid-column-gap: 19px;
}

.product-promo-name {
    text-align: left;
    font-weight: bold;
    color: #4D4E50;
    font-size: 28px;
    grid-column-start: 1;
    grid-column-end: -1;
    margin-top: 0;
    margin-bottom: 0;
}

.product-tertiary-nav-container {
    max-width: 240px;
    overflow: hidden;
}

.product-tertiary-nav-container .nav-tertiary:first-child {
    margin-top: 0;
}
.product-tertiary-nav-container .nav-tertiary:last-child {
    margin-bottom: 30px;
}

@media (min-width:769px) {
    .page-content .pagination-strip {
        padding-left: 261px;
    }
}

@media (max-width: 1080px) {

    .product-layout {
        flex-direction: column;
    }

    .product-list {
        width: 100%;
    }

    .product-layout-filters {
        width: 100%;
    }

    .product-tertiary-nav-container {
        max-width: 100%;
        overflow: hidden;
    }

    .product-tertiary-nav-container .nav-tertiary:last-child {
        margin-bottom: 10px;
    }

}


@media (max-width:768px) {

    .product-list {
        grid-template-columns: repeat(auto-fill, minmax(160px, auto));
        column-gap: 10px;
    }

}


