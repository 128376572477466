
.popup-overlay {
    position: fixed;
    z-index: 20;
}

.popup-overlay-display {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.15);
    z-index: 10;

    justify-content: center;
    align-items: center;
}

.general-overlay {
    position: fixed;
    z-index: 15;
}

.general-overlay-display {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(238, 200, 255, 0.5);
}