
body {
  margin: 0;
  font-family: var(--theme-font), 'Open Sans', 'Roboto', -apple-system, 'BlinkMacSystemFont', 'Segoe UI',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--footer-colour);
  color: var(--text-main);
}

#root {
  background-color: var(--background);
}

.highlight {
  color: var(--highlight);
  background-color: var(--primary);
}

.subheading {
  color: var(--primary);
  font-weight: 600;
}

.inactive {
  background: var(--inactive);
  color: var(--highlight);
  font-weight: bold;
  border-radius: var(--border-radius-large);
  display: flex;
  align-items: center;
  justify-content: center;
}
