
.landing-layout-header-img {

    background-image: url("/public/assets/header-with-cloud.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.landing-layout-header {
    height: 575px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.landing-layout-heading {
    width: min(1180px, calc(100vw - 60pt));
    margin: 0 auto;
    padding-top: 52px;
}

.landing-layout-heading p {
    font-weight: bold;
    color: #fff;
    font-size: 53px;
    text-align: left;
    margin: 0;
}

.landing-layout-note-container {
    margin-top: auto;
    background-color: rgba(0, 0, 0, 0.4);
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.landing-layout-note {
    color: #fff;
    text-align: left;
    width: min(1180px, calc(100vw - 60pt));
    margin: 0 auto;
}

.landing-layout-note-heading {
    font-size: 25px;
    font-weight: bold;
    margin: 0;
}

.landing-layout-note-text {
    margin: 0;
    font-size: 19px;
    font-weight: 300;
}

.bold {
    font-weight: bold;
}

.landing-layout-content {
    text-align: left;
    width: min(1180px, 100vw - 60pt);
    margin: 0 auto;
    color: #5a5a5d;
    font-size: 22px;
    font-weight: 300;
}

.landing-content-heading {
    font-size: 48px;
    font-weight: bold;
    margin: 50px 0 10px 0;
}

.landing-content-sub-heading {
    font-size: 32px;
    font-weight: 300;
    margin: 0 0 40px 0;
}

.landing-content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 160px;
    row-gap: 30px;
    margin-bottom: 100px;
}

.landing-content-grid-text {
    margin: 0;
}


.landing-content-grid-heading {
    margin: 0 0 5px 0;
}

.landing-content-grid br {
    margin-bottom: 16px;
}

@media (max-width: 768px) {

    .landing-content-grid {

        grid-template-columns: 1fr;

    }

    .landing-layout-heading p {
        font-size: 42px;
        text-shadow: #ccc 0 0 1px;
    }

    .landing-layout-note-heading {
        font-size: 18px;
    }

    .landing-layout-note-text {
        font-size: 14px;
    }

    .landing-content-heading {
        font-size: 38px;
    }

    .landing-content-sub-heading {
        font-size: 28px;
    }

}
