
:root {
    --search-bar-radius: 6pt;
}

.search-bar-container {
    display: flex;
    height: 44px;
}

.search-bar-input-container {
    display: flex;
    box-sizing: border-box;
    border: solid 1px #BBB;
    border-right: none;
    border-radius: var(--search-bar-radius) 0 0 var(--search-bar-radius);
    flex-grow: 1;
    background: var(--background);
}

.search-bar-input-container:hover {
    border: solid 1px #999;
    border-right: none;
}

.search-bar-container.focused .search-bar-input-container {
    border: solid 1px var(--primary);
    border-right: none;
}

.search-bar-container input {
    flex-grow: 1;
    font-size: 1em;
    padding: 0 0 0 12pt;
    background: none;
    border: none;
}

.search-bar-container input:focus {
    outline: none;
    margin-top: 0;
    margin-bottom: 0;
}

.search-bar-button {
    background-color: var(--primary);
    border-radius: 0 var(--search-bar-radius) var(--search-bar-radius) 0;
    cursor: pointer;

    display: flex;

    height: 100%;
    width: 30pt;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.search-bar-button img {
    margin: auto;
}

.search-and-suggest-container {
    position: relative;
}

.search-suggestions-container {
    display: none;
    background: var(--highlight);
    filter: drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.08));
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
}

.search-bar-container.focused + .search-suggestions-container {
    display: block;
}

.search-suggestions-container:hover {
    display: block;
}

.search-item-container {
    border-bottom: var(--primary) solid 1px;
    cursor: pointer;
}

.search-and-suggest-skeleton {
    cursor: pointer;
}

.search-item {
    display: flex;
}

.search-item p {
    margin: 6pt;
}

.search-item img {
    margin: auto 6pt;
}

.search-item-hover {
    background: var(--primary-muted);
}

.search-item-hidden {
    display: none;
}

.search-item-hover .search-item-hide-able {
    display: none;
}

.search-item-hover .search-item-hidden {
    display: block;
    cursor: auto;
}

.search-item-container .product-tile {
    margin: 0;
    filter: none;
    border-radius: 0;
}

.search-item-container .product-label {
    border-radius: 0 0 6pt 0;
}

input:focus::placeholder {
    color: transparent;
}


@media (max-width: 768px) {

    .search-bar-container {
        height: 34pt;
    }

    .search-bar-container p {
        display: none;
    }

    .search-bar-container.focused .search-bar-input-container {
        border: solid 1px var(--primary);
    }

    .search-bar-container img {
        max-height: 16pt;
    }

}