
.date-picker-container {
    text-align: left;
}

.date-picker-container .react-datepicker__input-container {
    margin-right: 20px;
}

.range-date-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
}

.date-picker-container div input {
    box-shadow: 0 0 0 1px #c4c4c4;
    border: none;
    width: 200px;
    font-size: 16px;
    padding: 10px 0;
    background: var(--background);
    border-radius: 10px;
    background-color: white;
    font-weight: 500;
    text-align: center;
    color: #838485;
}

.date-picker-container div input:focus {
    outline: none !important;
    border: none;
    box-shadow: 0 0 0 1px var(--secondary);
    max-width: 100%;
}

.date-picker-container {
    text-align: left;
}
