
.quick-entry-category-container {
    margin-bottom: 50px;
}

.quick-entry-header {
    display: flex;
    align-items: baseline;
    margin-top: 10px;
}

.quick-entry-heading {
    font-size: 19px;
    color: #5a5a5d;
    font-weight: bold;
    margin-right: 18px;
}

.quick-entry-expand {
    font-weight: bold;
    font-size: 15px;
    color: var(--secondary);
    text-decoration: underline;
    cursor: pointer;
}
